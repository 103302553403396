import { Injectable } from "@angular/core";
import { TranslateCacheService } from "@app/translate-cache/";
import { TranslateService } from "@ngx-translate/core";
import { NgcCookieConsentService } from "ngx-cookieconsent";

export interface Language {
  label: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  private availableLanguages: Language[] = [
    { label: "ru", value: "ru" },
    { label: "eng", value: "en" },
  ];

  constructor(
    private translate: TranslateService,
    private translateCacheService: TranslateCacheService,
    private ccService: NgcCookieConsentService,
  ) {}

  get currentLanguageLabel(): string {
    if (this.getCurrentLanguage()) {
      return this.getCurrentLanguage().label;
    } else return "ru"
  }

  get currentLanguageValue(): string {
    if (this.getCurrentLanguage()) {
      return this.getCurrentLanguage().value;
    } else return "ru"
  }

  changeLanguage(): void {
    const currentLang = this.currentLanguageValue;
    const currentLangIndex = this.availableLanguages.findIndex(
      (lang) => lang.value === currentLang,
    );
    if (currentLangIndex !== -1) {
      const nextLangIndex =
        (currentLangIndex + 1) % this.availableLanguages.length;
      this.setLanguage(this.availableLanguages[nextLangIndex].value);
    }
  }

  initLanguages(): void {
    this.addLanguages();
    this.translateCacheService.init();
  }

  private addLanguages(): void {
    this.translate.addLangs(this.getAvailableLanguagesValues());
  }

  private setLanguage(lang: string): void {
    if (this.getAvailableLanguagesValues().includes(lang)) {
      this.translate.use(lang);
    }
  }

  private getAvailableLanguagesValues(): string[] {
    return this.availableLanguages.map((lang) => lang.value);
  }

  private getCurrentLanguage(): Language {
    return this.availableLanguages.find(
      (lang) => lang.value === this.translate.currentLang,
    );
  }
}
